<template>
    <div>
        <autralis-error-comp :show="error !== null" :text="error"/>
        <autralis-success-comp :show="savedFlag === true"/>
        <autralis-modal-comp :modal="modal"/>
        <div class="flex cursor-pointer text-red-500 hover:text-red-800 font-medium" @click="deletePrompt">
            <div v-if="deleting" class="flex items-center">
                <span class="fas fa-spinner-third fa-spin mr-2"></span>
                <translate>Deleting</translate>
                ...
            </div>
            <div v-else><i class="fal fa-trash mr-2"/>Delete</div>
        </div>
    </div>
</template>

<script>
import Modal from "@/components/modal";
import {modalInfo} from "@/common/modal";
import ErrorBox from "@/components/error-box";
import SuccessBox from "@/components/success-box";
import {HOME_LIST_ITEMS_PER_PAGE} from "@/master/constants";
import {handleDeleteBusinessUnitGroups} from "@/master/views/Home/company/handlers";

export default {
    name: "DeleteGroup",
    props: {
        groupIds: {
            type: Array,
            default: () => []
        },
        companyId: {
            type: Number
        },
        page: {
            type: Number,
            default: 1
        },
        itemsPerPage: {
            type: Number,
            default: HOME_LIST_ITEMS_PER_PAGE
        }
    },

    data() {
        return {
            deleting: false,
            modal: modalInfo(),
            error: null,
            savedFlag: null
        }
    },

    methods: {
        deletePrompt() {
            const msg = this.$props.groupIds.length > 1
                ? "Are you sure that you want to delete those " + this.$props.groupIds.length + ' business unit groups?'
                : "Are you sure that you want to delete this business unit group?";

            this.modal.view('Confirm', msg, 'Confirm', 'exclamation-triangle', this.handleDelete)
        },

        async handleDelete() {
            try {
                this.deleting = true;
                this.savedFlag = null;
                this.error = null

                const payload = {
                    companyId: this.$props.companyId,
                    groupIds: this.$props.groupIds,
                    page: this.$props.page,
                    recordsPerPage: this.$props.itemsPerPage
                };

                // eslint-disable-next-line no-unused-vars
                await handleDeleteBusinessUnitGroups(payload, (response) => {
                    // Set total business unit groups
                    this.$store.commit('master/setTotalBusinessUnitGroups', response.totalCount)
                    // Set business unit groups
                    this.$store.commit('master/setBusinessUnitGroups', response.groups)
                    this.$emit('on-success', response)
                    this.savedFlag = true;
                }, (error) => {
                    this.error = error
                })

                this.deleting = false;

            } catch (err) {
                console.log(err)
                this.error = 'The business unit group could not be deleted. Please try again.'
                this.deleting = false;
            }
        },
    },

    components: {
        'autralis-modal-comp': Modal,
        'autralis-error-comp': ErrorBox,
        'autralis-success-comp': SuccessBox,
    }
}
</script>

<style scoped>

</style>